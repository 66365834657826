import useModal, { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import { FC, useCallback, useEffect, useState } from "react"
import styles from "@/components/styles/modal.module.css"
import paymentStyles from "@/components/styles/payment.module.css"
import u, { ELocalKey } from "@/util"
import { BehaviorSubject } from "rxjs"
import useConfig from "@/hooks/useGlConfig"
import { appendHost } from "../img"

export enum EStatus {
    SECOND = "SECOND", // 第2类型的购买弹窗 240
    THIRD = 'THIRD', // 第3类型的购买弹窗 200
    IDLE = "IDLE", // 支付弹窗初始状态
    NOBUY = "NOBUY", // 未点击支付
    BOUGHT = "BOUGHT" // 点击支付成功了
}

interface IPayment {
    status: EStatus,
}

export const PaymentSubject$ = new BehaviorSubject<IPayment>({
    status: EStatus.IDLE
})

const PaymentModal: FC = () => {
    const [modalData, closeModal] = useModal();
    const [config] = useConfig()
    const [displayType, setDisplayType] = useState<2 | 3>(2)

    useEffect(() => {
        const sub = PaymentSubject$.subscribe((res) => {
            if (res.status === EStatus.BOUGHT) {
                closeModal();
                localStorage.removeItem(ELocalKey.__P_SECOND);
                localStorage.removeItem(ELocalKey.__P_THIRD);
                return;
            }
            if (res.status === EStatus.NOBUY) {
                localStorage.setItem(ELocalKey.__P_SECOND, u.getCurrentTime() as any)
            }
            if (res.status === EStatus.SECOND) {
                setDisplayType(2);
                return modalSubject$.next({
                    id: EModalTypes.PAYMENT
                })
            }
            if (res.status === EStatus.THIRD) {
                setDisplayType(3)
                return modalSubject$.next({
                    id: EModalTypes.PAYMENT
                })
            }

        })
        return () => sub.unsubscribe()
    }, [])

    useEffect(() => {
        if (window.location.href.indexOf("user/charge") >= 0) return;

        const fn = () => {
            if (u.isVip()) return;
            PaymentSubject$.next({
                status: EStatus.NOBUY
            })
        }

        window.addEventListener('popstate', fn);
        return () => {
            window.removeEventListener("popstate", fn);
        }
    }, [])

    const handleAccept = useCallback(() => {
        const randV = `${u.charToRandValue(`${displayType}`)}${u.randomHash()}`;
        try {
            let val = localStorage.getItem("__GD") as any;
            val = JSON.parse(val);
            if (val[0]?.id) {
                return u.open(`/user/charge?id=${val[0]?.id}&t=${randV}`)
            }
        } catch { }
    }, [displayType])

    const handleDecline = useCallback(() => {
        if (displayType === 2) {
            localStorage.removeItem(ELocalKey.__P_SECOND)
            localStorage.setItem(ELocalKey.__P_THIRD, u.getCurrentTime() as any)
            closeModal()
        }
        if (displayType === 3) {
            localStorage.removeItem(ELocalKey.__P_THIRD)
            localStorage.setItem(ELocalKey.__P_SECOND, u.getCurrentTime() as any)
            closeModal()
        }
    }, [displayType, closeModal])

    return (
        modalData?.show && modalData?.id === EModalTypes.PAYMENT && <div className={styles.common}>
            <div className={`${styles.body} cls_common ${paymentStyles.pbody}`}>
                {/* <div onClick={() => u.formUser(EVIPtype.BUY, modalData?.data?.data?.data)} className={styles.close}>X</div> */}
                <div className={`${styles.content} ${paymentStyles.content}`}>
                    <div className={paymentStyles.eggmodal}>
                        <img src={displayType === 2 ? appendHost('/images/goldegg.png') : appendHost('/images/vipegg.png')} />
                        <div className={paymentStyles.eggcontent}>
                            <div onClick={handleAccept} className={paymentStyles.btn1}>{displayType === 2 ? '七折优惠，立即购买' : `${config?.promo_2}元终身VIP特惠`}</div>
                            <div onClick={handleDecline} className={paymentStyles.btn2}>{displayType === 2 ? '残忍拒绝' : '继续残忍拒绝'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PaymentModal
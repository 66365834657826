import ImageList from "@/components/titleWithList";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import { useParams } from "react-router";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import u, { ELocalKey, cancelAllPreviousFetchRequests } from "@/util";
// import Back from "@/components/back";
import styles from "./styles/topic.module.css"
import Img, { appendHost } from "@/components/img";
import Button from "@/components/button";
import useUser from "@/hooks/useUser";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import copy from "copy-to-clipboard";
import Back from "@/components/back";
import BuyTopicModal from "@/components/modal/buyTopicModal";
import useFlipFlop from "@/hooks/useFlipFlop";
import DownloadZip from "@/components/modal/downloadZip";
import Category from "@/components/category";
import useConfBase from "@/hooks/useConfBase";

const CategoryTopic: FC = () => {
    const { channel, id } = useParams()
    const { req } = useAxios("topic/detail", "post");
    const { req: req2 } = useAxios("user/getTopics", "post");
    const [reset, setReset] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([])
    const [isBuy, setIsBuy] = useState<boolean>(false)
    const [dta, setDta] = useState<any>({})
    const [user] = useUser()
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])
    const [newImgList, setNewImgList] = useState<any>([])
    const [config] = useConfBase();
    const [showGif, setShowGif] = useState<any>("")

    const fetchPurchasedList = useCallback(async () => {
        let token = '';
        if(u.isLogin() && !user?.token){
            let __data = localStorage.getItem(ELocalKey._USER);
            let __rdata = JSON.parse(u.decrypt(__data))
            token = __rdata?.token;
        }else{
            token = user?.token;
        }

        if (u.isLogin() && token) {
            const res = await req2({
                token: token
            });
            if (res) {
                setIsLoad(true)
                setIsBuy(u.isBuyTopic(res?.data.topic_videos, id))
            }else{
                setIsLoad(true)
            }
        }

    }, [])

    useEffect(() => {
        fetchPurchasedList();
    }, [fetchPurchasedList])


    const fetchDataList = useCallback(async (page?: number) => {
        if (!channel) return;
        const params = {
            channel,
            id,
            page: page || 1
        }
        const res = await req(params);
        if (res) {
            console.log('details',res)
            const imgurl = res?.data?.list?.gif_images_convert?.map((x: any) => `${config?.image_url}/${x}.txt`);
            console.log('gif =============== ', imgurl);
            setEncryptUrls(imgurl)
            
            setDta(res?.data?.list);
            if (Array.isArray(res?.data?.list?.list) && Array.isArray(res?.data?.list?.free_list)) {
                let mergeList: any[] = [] ;
                res?.data?.list?.free_list?.map((item: any) => {
                    item.is_free = true;
                    mergeList.push(item);
                })
                res?.data?.list?.list?.map((item: any) => {
                    item.is_free = false;
                    mergeList.push(item);
                })
                console.log('mergeList ---- ', mergeList)
                setList(mergeList)
            }
            if(!u.isLogin()){
                setIsLoad(true)
            }
        }
    }, [channel, id])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])

    const goExternal = useCallback(() => {
        // const __val = u.getStatsParams(false, "tese");
        // await req2({ ...__val, category: "external", name: dta?.host?.url })
        console.log(list)
        return window.open(dta?.host?.url)
    }, [dta])

    const handleBuy = useCallback((price?: any) => {
        let id =window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1,window.location.pathname.length);
        if (!u.isLogin()) {
            localStorage.setItem(ELocalKey._BT,id + '-' + price);
            return window.location.href = "/login"
        }
        try {
            let i = price.toString();
            // i = u.encrypt(i);
            u.open(`/user/chargetopic?id=`+id+`&i=`+i+`&ty=topic`)
        } catch(err) { 
            console.log(err)
        }
    }, [])

    const [flipText, flipHander] = useFlipFlop("登录", "登录中...")
    const popupModal = useCallback((price?: any) => {
        modalSubject$.next({
            id: EModalTypes.BUYTOPIC,
            data: {price: price },
            event: () => flipHander(0)
        })
    }, [])

 

    const copyVal = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.MASK,
            child: "您已成功复制下载链接～"
        })
    }, [])
    
    const TopicJumpEl = useCallback((btm?: boolean) => {
        return <>
            {/* <div className={styles.ts_sin_vid}><span onClick={goExternal}>本区</span>视频由{dta?.host?.nickname}独家提供</div> */}
            {/* <div className={`${styles.ts_container} ${styles.main1} ${btm ? 'mb20' : ''}`}>
                <div onClick={goExternal}>
                    <Img src={dta?.host?.logo} cls={`${styles.ts_logo}`} />
                </div>
                <div className="grid gap10">
                    <div className="fl align_center ">
                        <div className={styles.ts_title}>
                            {dta?.host?.nickname}
                        </div>

                        <Button event={goExternal} cls={`btn_sty2 ${styles.ts_btn}`}>
                            进入官网
                        </Button>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.desc}>
                        {u.dot(dta?.host?.desc)}
                    </div>
                </div>
            </div> */}
        </>
    }, [goExternal, dta])

    const handleVIP = useCallback((row: any) => {
        if (!u.isLogin()) {
            return window.location.href = "/login"
        }
        if (!u.isVip()) {
            return window.location.href = "/user/buy"
        }
    }, [])

    const dlZip = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.DLZIP,
            data: {url: val },
            event: () => flipHander(0)
        })
    }, [])

    return (
        <div className="grid gap10">
            {<DownloadZip />}
            {/* <div className="mw1100 category_wrap">
             <Category showSiteCili={false} showTopicOnly={true}/>
            </div> */}
            <Back cls={'mt20'}>主题详情</Back>
            <div className="mt20 m10">
                
                {isLoad && 
                <>

                <div className={`mw1100 ${styles.ts_title_top}`}>
                    
                    <div className={` ${styles.ts_title}`}>{dta?.title}</div>
                    <div className={styles.left_cover}>
                        <div style={{width: '100%',display: 'flex', justifyContent:'end'}}>
                            {!isBuy && 
                            <>
                            {!isBuy && u.isVip() && <>
                                <div className={`${styles.pricetag}`} style={{fontWeight: 500, width: '50%',paddingRight:'20px',fontSize:'12px',gap: '5px'}}>
                                    <div style={{color:'#FF347D'}} className={`${styles.currentprice}`}>VIP价格: {dta?.vip_price} 元</div>
                                    <div style={{textDecoration:'line-through'}}>价格: {dta?.price} 元</div>
                                </div>
                            </>}
                            {!isBuy && !u.isVip() && <>
                                <div className={`${styles.pricetag}`} style={{fontWeight: 500, width: '50%',paddingRight:'20px',fontSize:'12px',gap: '5px'}}>
                                    <div className={`${styles.currentprice}`}>当前价格: {dta?.price} 元</div>
                                    <div style={{color:'#FF347D', display:'flex', alignItems:'center', gap: '5px',justifySelf:'flex-end'}} >VIP价格: {dta?.vip_price} 元 <div className={`${styles.becomevip}`} onClick={handleVIP}><span style={{paddingRight:'5px'}}>开通VIP</span><Img height={12} width={12} src={appendHost("/images/youbianjiantou.png")} /></div></div>
                                </div>
                            </>}
                            </>
                            }

                            <div className={`${styles.buttontag}`}  style={{display: 'flex',gap:'10px'}}>

                            {!isBuy && 
                            <>
                            <Button event={()=>{handleBuy(u.isVip() ? dta?.vip_price : dta?.price)}} cls={`btn_sty2`}>
                                    立即购买
                            </Button>
                            <Button event={() => {popupModal(dta?.price)}} cls={`btn_sty2 btn_gray`}>
                                下载捆绑包
                            </Button>
                            </>
                            }

                            {isBuy && 
                            <Button event={() => dlZip(dta?.file)} cls={`btn_sty2`}>
                                下载捆绑包
                            </Button>
                            }
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.pricetagmobile}`} style={{fontWeight: 500, width: '100%',gap: '20px', justifyContent: 'right'}}>
                            {!isBuy && !u.isVip() && <>
                                <div style={{color:'#FF347D', display:'flex', alignItems:'center', gap: '5px',justifySelf:'flex-end'}} >VIP价格: {dta?.vip_price} 元 <div className={`${styles.becomevip}`}  onClick={handleVIP}><span style={{paddingRight:'5px'}}>开通VIP</span><Img height={12} width={12} src={appendHost("/images/youbianjiantou.png")} /></div></div>
                                <div style={{display:'flex', alignItems:'center', fontWeight: 700}} >当前价格: {dta?.price} 元</div>
                            </>
                            }
                            {!isBuy && u.isVip() && <>
                                <div style={{color:'#FF347D', display:'flex', alignItems:'center', gap: '5px',justifySelf:'flex-end',fontWeight: 700}} >VIP价格: {dta?.vip_price} 元</div>
                                <div style={{display:'flex', alignItems:'center', textDecoration:'line-through'}} >价格: {dta?.price} 元</div>
                            </>
                            }
                            </div>
                </div>
                <div className={`mw1100 ${styles.ts_container} ${styles.main}`}>
                    <div >
                        {/* <Img src={dta?.host?.logo} cls={`${styles.ts_logo}`} /> */}
                    </div>

                    <div>
                        <div className={styles.desc}>
                            {dta?.desc}
                            <div className={styles.importanttext}>*用户购买主题包后，请到右上角头像“个人中心” —“已购买主题包” 永久在线观看或下载</div>
                        </div>
                    </div>

                     
                </div>

                {encryptUrls && encryptUrls.length > 0 && 
                <>
                <div className={`mw1100 ${styles.gif_title}`} style={{marginBottom:'20px', marginTop: '20px'}}>精彩片段</div>
                <div className={`mw1100 ${styles.gif_wrapper}`} style={{marginBottom:'20px', marginTop: '20px'}}>
                {encryptUrls.map((x, i) => {
                    return (
                    <div className={`${styles.gif_images}`} >
                      <Img reload={reset} height={30} encryptUrl={(encryptUrls[i])} src={newImgList[i]} >
                                              {(_decryptUrl: any) => {
                                                  return <>
                                                  <div onClick={() => {_decryptUrl.includes("loading") ? setShowGif(""):setShowGif(_decryptUrl);}} style={{position:'relative', float:'inline-start'}}>
                                                    <Img src={appendHost("/images/zoom.png")} width={20} height={20} style={{position:'absolute', top:'0', right:'0'}}/>
                                                      <Img reload={reset} cls="lazy" style={{ objectFit: 'contain', width: "100%", height: "100%" }} src={_decryptUrl} />
                                                    </div>
                                                  </>
                                              }}
                      </Img>
                      </div>)
                })}
                </div></>}
                </>
                }

                {isLoad && 
                <div className={`mw1100 grid gap30 ${styles.ts}`}>

                    <div>
                        {/* <div className="main">
                            <div className="ht">福利视频</div>
                            <div onClick={goExternal}>更多视频</div>
                        </div> */}
                        {/* {TopicJumpEl(true)} */}
                        <ImageList event={popupModal} isBuy={isBuy} updateTime={dta?.update_time} title={dta?.title} topicPrice={dta?.price} reset={reset} channel={'topic'} list={list.map((x) => ({ ...x, thumb: `${x.thumb}`, isLs: 1 }))} idkey="title" idValue="id" imgUrlkey="thumb" >
                        </ImageList>
                    </div>
                </div>
                }
            </div>

            <div>
                <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={(e) => {
                    fetchDataList(e.current);
                    setReset(true);
                }} />
            </div>

            {showGif && showGif !== "" && <>
            <div className={`${styles.centered_gif_wrapper}`} onClick={() => setShowGif("")}>
                <div className={`${styles.centered_gif}`}><img src={showGif} alt=""/></div>
            </div>
            </>}
     
            {TopicJumpEl()}
            {<BuyTopicModal />}
            <Footer />
        </div>
    )
}
export default CategoryTopic
import Button from "@/components/button";
import Img, { appendHost } from "@/components/img";
import Input from "@/components/input";
import LoginWrapper from "@/components/loginWrapper";
import styles from "./styles/register.module.css"
import { Link } from "react-router-dom";
import { FC, useCallback, useEffect, useState } from "react";
import useFlipFlop from "@/hooks/useFlipFlop";
import useAxios from "@/hooks/useAxios";
import useUpdateForm from "@/hooks/useUpdateForm";
import useValidation from "@/hooks/useValidation";
import { vID } from "@/const/validate.const";
import Select from "@/components/select";
import { areaCodes } from "@/const/areaCode";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import u from "@/util";
// import Modal from "@/components/modal";

let int = '' as any; 
const Forget: FC = () => {
    const [formData, setFormData] = useState<any>({
        code: "",
        password: "",
        phone_code: "",
        phone: "",
    })
    const [isClick, setIsClick] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(60)

    const [flipText, flipHander] = useFlipFlop("找回密码", "找回密码...")
    const { req } = useAxios("user/forgetPassword", 'post');
    const { req: req2 } = useAxios("phone/sendSms", 'post');
    const updateForm = useUpdateForm(setFormData);

    const [isValidSubmit, msgStatus] = useValidation(formData, {
        password: [vID.PWD_EMPTY, vID.MIN_TEXT_6_20],
        password2: [vID.PWD2_EMPTY, vID.PWD_PWD2_NOT_MATCH],
        phone_code: [vID.PHONE_AREA_CODE],
        phone: [vID.TRUE_FORMAT_PHONE],
        code: [vID.CODE]
    }, "password")

    const changeLoder = useCallback((x: any) => {
        return {
            name: `${x.value}${x.name}`,
            value: x.value
        }
    }, [])

    const selectOnchange = useCallback((val: any) => {
        const _val = val.split("-");
        updateForm({
            phone_code: _val[0],
        })
    }, [updateForm])

    const findPwd = useCallback(async () => {
        if (!isValidSubmit) return;
        flipHander(1);
        const res = await req(formData);
        if (res) {
            if (res.code == 0) {
                return u.open("/login")
            } else if (res.code == 5004) {

            } else {
                return modalSubject$.next({
                    id: EModalTypes.NORMAL,
                    innerTitle: res.msg,
                    btn: true,
                    event: () => flipHander(0)
                })
            }
        }
    }, [flipHander])

    const updateTime = useCallback(() => {
        setIsClick(true)
        int = setInterval(function() {
            setTimer((t: any) => t - 1);
        }, 1000);
    }, [])

    useEffect(() => {
        let _t = timer;
        if (_t <= 0) {
            setTimer(60);
            setIsClick(false)
            return clearInterval(int) 
        } 
    }, [timer])


    const fetchCode = useCallback( async () => {
        if (formData.isClick) return;
        if (!formData.phone || !formData.phone_code) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: "请填写正确的手机号码",
                btn: true,
                event: () => flipHander(0)
            })
        }   
        const res = await req2({ ...formData, type: "forget"});
       
        if (res?.code === 0) {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: "发送成功，请注意查收",
                btn: true,
                event: () => flipHander(0)
            })
        } else {
            return modalSubject$.next({
                id: EModalTypes.NORMAL,
                innerTitle: res.msg,
                btn: true,
                event: () => {
                    console.log('z')
                    flipHander(0)
                    updateTime()
                }
            })
        }
    }, [updateTime, formData, flipHander])

    return (
        <>
            <div className={`grid ${styles.register} bgfa`}>
                <div className={`grid ${styles.form} mauto`}>
                    <div className={styles.header}>忘记密码</div>
                    <div className={`grid ${styles.inner2}`}>
                        <div className={`grid ${styles.inner3}`}>
                            <div>
                                <Input errMsg={[msgStatus[2], msgStatus[3]]} onChange={(v) => updateForm({ ...formData, "phone": v })} prefix={
                                    <div className={styles.phone_select}>
                                        <Img src={appendHost("/images/icon/icon_user.png")} width={20} height={20} />
                                        <Select list={areaCodes} loader={changeLoder} onChange={selectOnchange} />
                                    </div>} line placeholder="输入手机号码" />
                            </div>
                            <div>
                                <Input endFix={
                                    <div className="fz12 point">
                                        {isClick ? timer : <span onClick={fetchCode}>点击获取</span> }
                                    </div>
                                } errMsg={msgStatus[4]} prefix={<Img src={appendHost("/images/icon/verification_code.png")} width={20} height={20} />} onChange={(v) => updateForm({ ...formData, "code": v })} line placeholder="输入验证码" />
                            </div>
                            <div>
                                <Input errMsg={msgStatus[0]} prefix={<Img src={appendHost("/images/icon/icon_password.png")} width={20} height={20} />} onChange={(v) => updateForm({ ...formData, "password": v })} line type="password" placeholder="密码" />
                            </div>
                            <div>
                                <Input errMsg={msgStatus[1]} prefix={<Img src={appendHost("/images/icon/icon_password2.png")} width={20} height={20} />} onChange={(v) => updateForm({ ...formData, "password2": v })} type="password" line placeholder="确认密码" />
                            </div>
                        </div>
                        <div>
                            <Button event={findPwd} cls={`btn_sty1 ${u.themeBtnCls()}`}>
                                <Link className="a_w" to={u.formRegister(true)}>{flipText}</Link>
                            </Button>
                            <div className={styles.btm}>
                                <Link className={`${styles.click_register} fw500 d_none`} to={u.formLogin(true)}>点击登录</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginWrapper />
            </div>
            {/* <Modal /> */}
        </>
    )
}
export default Forget;
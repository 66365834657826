import React, { FC, useMemo } from "react"
import u from "@/util"
import Img, { appendHost } from "@/components/img"

const Fdm: FC = () => {
    const meomoImgs = useMemo(() => {
        const _p = appendHost(`/images/help/fdm/`)
        return [`${_p}Mac.jpg`, `${_p}win.jpg`, `${_p}h5.jpg`]
    }, [])
    return (
            <div>
                <Img style={{ height: "100%", width: "100%", "minHeight": "2500px" }} src={meomoImgs[u.osType()]} alt="" />
            </div>
    )
}
export default Fdm
import Img, { appendHost } from "@/components/img";
import u from "@/util";
import { FC, useMemo } from "react"

const Xunlei: FC = () => {
    const src = useMemo(() => (u.isMobile() ? 'xunlei_mobile' : 'xunlei_tutorial'), [])
    return <div>
        <Img cls="fill1" style={{ height: "100%", width: "100%", "minHeight": "2500px" }} src={appendHost(`/images/help/${src}.png`)} alt="" />
    </div>
}
export default Xunlei;